import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import Head from "next/head";
import React from "react";
import ImageHeader from "@/public/webcam-bg.png";
import ImageBGBottom from "@/public/relief.png";
import ImageBGTop from "@/public/relief-beige.png";
import Text from "../../../Core/Text";
import * as styles from "./Cms.module.scss";
import Image from "next/image";
import dynamic from "next/dynamic";
import LangSwitchBlock from "@/common/components/LangSwitchBlock";
import useTranslation from "next-translate/useTranslation";

const DynamicFollowBlock = dynamic(
  () => import("@/common/components/FollowBlock"),
  {
    loading: () => <p>Loading...</p>,
  }
);

export default function CmsPage({ blok, settings, dataConfig, nestedData }) {
  const { t } = useTranslation("common");

  // Define meta title and description
  const title = dataConfig?.meta_title || blok?.hero?.[0]?.title || t("courchevel");
  const description = dataConfig?.meta_description || title;
  const robots = dataConfig?.hide_from_search ? "noindex, nofollow" : "index, follow";

  const faq = blok?.faq?.[0];

  return (
    <div className={styles.cmsPage} {...storyblokEditable(blok)}>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        {robots && <meta name="robots" content={robots} />}
      </Head>

      <div className={`${styles.cmsPageContent} relative`}>
        {/* Background Decoration */}
        <div
          className="absolute top-[30%] bottom-[10%] left-0 right-0 z-0 bg-no-repeat bg-background-beigeDark bg-contain"
          style={{
            backgroundImage: `url(${ImageBGBottom.src})`,
            backgroundPosition: "bottom center",
          }}
        >
          <Image
            className="absolute top-0 left-0 object-contain w-full h-auto -translate-y-[100%]"
            src={ImageBGTop}
            alt="Background Top"
          />
        </div>

        {/* Hero Section */}
        {blok?.hero?.[0] && (
          <div className={`${styles.heroSingle} relative`}>
            <div className={`${styles.heroSingleSlide} relative lg:rounded-2xl overflow-hidden mt-14`}>
              <Image
                className="absolute top-0 left-0 object-cover w-full h-full"
                src={blok.hero[0].Image?.filename || ImageHeader}
                width={1920}
                height={720}
                alt={blok.hero[0].title || "Hero Image"}
              />
            </div>
          </div>
        )}

        {blok?.hero?.[0] && (
          <div className="container relative">
            <Text as="h2" tag="h1" className="text-h1 my-6 lg:mt-12 lg:mb-16">
              {blok.hero[0].title || dataConfig?.name || "Default Title"}
            </Text>

            {blok.showLang && <LangSwitchBlock />}

            <div className="max-w-[100vw] m-auto pb-20">
              {blok.content &&
                blok.content.map((contentBlock) => (
                  <StoryblokComponent key={contentBlock._uid} blok={contentBlock} />
                ))}
            </div>
          </div>
        )}
      </div>

      {/* Render iframe blocks full screen if no hero exists */}
      {!blok?.hero?.[0] &&
        blok.content &&
        blok.content.map((contentBlock) => {
          if (contentBlock.component === "iframe_cms" && contentBlock.code) {
            return (
              <div
                key={contentBlock._uid}
                style={{ height: "100vh", width: "100vw" }}
                dangerouslySetInnerHTML={{ __html: contentBlock.code }}
              />
            );
          }
          return null;
        })}

      {/* Cross Content */}
      {blok.cross_content &&
        blok.cross_content.map((crossContentBlock) => (
          <StoryblokComponent
            blok={crossContentBlock}
            key={`ccbi-${crossContentBlock._uid}`}
            nestedData={nestedData}
          />
        ))}

      {/* FAQ Block */}
      {faq && <StoryblokComponent blok={faq} />}

      {/* Dynamic Follow Block (if needed) */}
      {/* <DynamicFollowBlock /> */}
    </div>
  );
}
